/** Need this CSS to account for Spectrum styling for native Slider **/

.spectrum-ColorSlider {
  --spectrum-colorslider-handle-hitarea-border-radius: 0%;
  --spectrum-colorslider-handle-hitarea-width: var(--spectrum-global-dimension-size-300);
  --spectrum-colorslider-handle-hitarea-height: var(--spectrum-global-dimension-size-300);
}

.spectrum-ColorSlider {
  position: relative;
  display: block;
  width: var(--spectrum-colorslider-default-length, var(--spectrum-global-dimension-size-2400));
  height: var(--spectrum-colorslider-height, var(--spectrum-global-dimension-size-300));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.spectrum-ColorSlider {
  --spectrum-colorslider-border-color: var(--spectrum-colorarea-border-color);
}

.spectrum-ColorSlider-slider {
  opacity: 0.0001;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0;
}

.spectrum-ColorSlider-checkerboard {
  background-size: var(--spectrum-global-dimension-static-size-200, 16px) var(--spectrum-global-dimension-static-size-200, 16px);
  background-position:
    0 0,
    0 var(--spectrum-global-dimension-static-size-100, 8px),
    var(--spectrum-global-dimension-static-size-100, 8px) calc(-1 * var(--spectrum-global-dimension-static-size-100, 8px)),
    calc(-1 * var(--spectrum-global-dimension-static-size-100, 8px)) 0;
}

.spectrum-ColorSlider-gradient,
.spectrum-ColorSlider-checkerboard {
  width: 100%;
  height: 100%;
  border-radius: var(--spectrum-colorslider-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-ColorSlider-checkerboard {
  background-color: var(--spectrum-global-color-static-white, rgb(255, 255, 255));
  background-image: linear-gradient(-45deg, transparent 75.5%, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 75.5%), linear-gradient(45deg, transparent 75.5%, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 75.5%),
    linear-gradient(-45deg, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 25.5%, transparent 25.5%), linear-gradient(45deg, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 25.5%, transparent 25.5%);
}

.spectrum-ColorSlider-handle {
  left: 0;
  top: 50%;
}

.spectrum-ColorHandle {
  --spectrum-colorhandle-background-offset: calc(-1 * var(--spectrum-global-dimension-static-size-25, 2px));
  --spectrum-colorhandle-checkerboard-size: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorhandle-animation-duration: var(--spectrum-global-animation-duration-100, 130ms);
  --spectrum-colorhandle-animation-easing: ease-in-out;
  --spectrum-colorhandle-hitarea-size: var(--spectrum-global-dimension-size-300);
}
.spectrum-ColorHandle {
  border-color: var(--spectrum-colorhandle-inner-border-color, var(--spectrum-global-color-static-white));
  box-shadow: 0 0 0 var(--spectrum-colorhandle-outer-border-size, var(--spectrum-global-dimension-static-size-10)) var(--spectrum-colorhandle-outer-border-color, var(--spectrum-alias-colorhandle-outer-border-color));
  background-color: var(--spectrum-global-color-static-white, rgb(255, 255, 255));
  background-image: linear-gradient(-45deg, transparent 75.5%, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 75.5%), linear-gradient(45deg, transparent 75.5%, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 75.5%),
    linear-gradient(-45deg, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 25.5%, transparent 25.5%), linear-gradient(45deg, var(--spectrum-global-color-static-gray-500, rgb(188, 188, 188)) 25.5%, transparent 25.5%);
}

.spectrum-ColorHandle {
  --spectrum-colorhandle-background-offset: calc(-1 * var(--spectrum-global-dimension-static-size-25, 2px));
  --spectrum-colorhandle-checkerboard-size: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorhandle-outer-border-color: rgba(0, 0, 0, 0.42);
}

.spectrum-ColorHandle,
.spectrum-ColorHandle:after {
  border-radius: 100%;
}
.spectrum-ColorHandle-color {
  box-shadow: inset 0 0 0 var(--spectrum-colorhandle-outer-border-size, var(--spectrum-global-dimension-static-size-10)) var(--spectrum-colorhandle-outer-border-color, var(--spectrum-alias-colorhandle-outer-border-color));
}
.spectrum-ColorHandle-color {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.spectrum-ColorHandle {
  display: block;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: var(--spectrum-colorhandle-size, var(--spectrum-global-dimension-size-200));
  height: var(--spectrum-colorhandle-size, var(--spectrum-global-dimension-size-200));
  margin-left: calc(-1 * var(--spectrum-colorhandle-size, var(--spectrum-global-dimension-size-200)) / 2);
  margin-top: calc(-1 * var(--spectrum-colorhandle-size, var(--spectrum-global-dimension-size-200)) / 2);
  border-width: var(--spectrum-colorhandle-inner-border-size, var(--spectrum-global-dimension-static-size-25));
  border-style: solid;
  background-size: var(--spectrum-global-dimension-static-size-200, 16px) var(--spectrum-global-dimension-static-size-200, 16px);
  background-position:
    var(--spectrum-colorhandle-background-offset) var(--spectrum-colorhandle-background-offset),
    var(--spectrum-colorhandle-background-offset) calc(var(--spectrum-colorhandle-checkerboard-size) + var(--spectrum-colorhandle-background-offset)),
    calc(var(--spectrum-colorhandle-checkerboard-size) + var(--spectrum-colorhandle-background-offset)) calc(-1 * var(--spectrum-colorhandle-checkerboard-size) + var(--spectrum-colorhandle-background-offset)),
    calc(-1 * var(--spectrum-colorhandle-checkerboard-size) + var(--spectrum-colorhandle-background-offset)) var(--spectrum-colorhandle-background-offset);
  transition:
    width var(--spectrum-colorhandle-animation-duration) var(--spectrum-colorhandle-animation-easing),
    height var(--spectrum-colorhandle-animation-duration) var(--spectrum-colorhandle-animation-easing),
    border-width var(--spectrum-colorhandle-animation-duration) var(--spectrum-colorhandle-animation-easing),
    margin-left var(--spectrum-colorhandle-animation-duration) var(--spectrum-colorhandle-animation-easing),
    margin-top var(--spectrum-colorhandle-animation-duration) var(--spectrum-colorhandle-animation-easing);
}

.spectrum-ColorSlider-gradient,
.spectrum-ColorSlider-checkerboard {
  width: 100%;
  height: 100%;
  border-radius: var(--spectrum-colorslider-border-radius, var(--spectrum-alias-border-radius-regular));
}

#alphaSlider {
  margin-top: 8px;
  width: 166px;
}
